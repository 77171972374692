import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getAxios from "common/axios";

const axios = getAxios();
const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

/**
 * Async action to fetch excursion data from the server.
 * Expects an object with `showId` and `orgId` as input.
 * The server responds with a payload containing a `products` array.
 */
export const fetchExcursionData = createAsyncThunk(
  "excursion/fetchExcursionData",
  async ({ showId, orgId }) => {
    const response = await axios.get(
      `${REACT_APP_SERVER_BASE_URL}/floorplan/products/excursion/${showId}/${orgId}/`
    );
    return response.data;
  }
);

/**
 * Group and sort products by productSubClass.
 * Each productSubClass (e.g., "Day 1", "Day 2") forms a group.
 * @param {Array} products - List of valid products.
 * @returns {Array} Grouped and sorted products in the desired format:
 * [
 *   { day: "Day 1", products: [ ... ] },
 *   { day: "Day 2", products: [ ... ] },
 *   ...
 * ]
 */
const groupAndSortProductsBySubclass = (products) => {
  const grouped = products.reduce((acc, product) => {
    const day = product.productSubClass.trim(); // Ensure clean subclass
    if (!acc[day]) {
      acc[day] = [];
    }
    acc[day].push(product);
    return acc;
  }, {});

  // Sort keys (subclasses) alphabetically, case-insensitive
  const sortedKeys = Object.keys(grouped).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );

  // Create sorted grouped array
  return sortedKeys.map(day => ({
    day,
    products: grouped[day]
  }));
};

/**
 * Redux slice for managing excursion data.
 * The excursion store structure:
 * {
 *   excursions: [
 *     {
 *       day: "Day 1",         // Subclass or grouping key (e.g., "Day 1", "Day 2").
 *       products: [           // Array of products under this subclass.
 *         { id, name, price, ... }, // Product object details.
 *         ...
 *       ]
 *     },
 *     ...
 *   ],
 *   status: "idle" | "loading" | "succeeded" | "failed", // Fetch status.
 *   error: null | string // Error message, if fetch fails.
 * }
 */
const excursionSlice = createSlice({
  name: "excursion",
  initialState: {
    excursions: [], // Grouped and sorted products by productSubClass.
    status: "idle", // Status of fetchExcursionData request.
    error: null,    // Error message in case of failure.
  },
  reducers: {
    /**
     * Reducer to update excursions directly by grouping and sorting products.
     * Useful for manual updates or local manipulations.
     */
    updateExcursions: (state, action) => {
      const groupedProducts = groupAndSortProductsBySubclass(action.payload);
      state.excursions = [
        ...groupedProducts
      ];
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExcursionData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchExcursionData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.excursions = action.payload.products || []; 
      })
      .addCase(fetchExcursionData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

// Export actions and reducer for usage in the application.
export const { updateExcursions } = excursionSlice.actions;
export default excursionSlice.reducer;
