import { combineReducers } from "redux";
import showReducer from "store/reducers/showReducer";
import allShowsReducer from "store/reducers/showsReducer";
import currentUserReducer from "store/reducers/currentUserReducer";
import sessionsReducer from "store/reducers/sessionsReducer";
import allShowsPastReducer from "store/reducers/pastShowReducer";
import pageNotFoundReducer from "store/reducers/pageNotFoundReducer";
import internalServerErrorReducer from "store/reducers/internalServerErrorReducer";
import hashtagsReducer from "store/reducers/hashtagsReducer";
import exhibitorSearchReducer from "store/reducers/exhibitorSearchReducer";
import exhibitorsReducer from "store/reducers/exhibitorPortal/exhibitorsReducer";
import themeSwitchReducer from "store/reducers/themeSwitcher";
import contactsReducer from "./exhibitorPortal/contactsReducer";
import productsReducer from "./productsReducer";
import chatReducer from "../slices/chatSlice";
import attendeeQuestionsReducer from "../slices/attendeeQuestionsSlice";
import sponsorsReducer from "../slices/sponsorsSlice";
import attendeesReducer from "../slices/attendeesSlice";
import productReducer from "../slices/productsSlice";
import productsOfAccountReducer from "../slices/productsOfAccountSlice";
import contactReducer from "../slices/contactSlice";
import accountSearchSlice from "store/slices/accountSearchSlice";
import registrationReducer from "../slices/registrationSlice";
import excursionReducer from "../slices/excursionSlice";
/*
  'show' data format: 
  show -> showId -> booths <list>
  -> exhibitors <list>
  -> availablePublicly: "true"
  -> showEndDate: "2022-04-04"
  -> showName: "Crypto 2022"
  -> showStartDate: "2022-04-01"
  -> _id: "a025j000003FZYMAA4"
 */
export default combineReducers({
  shows: allShowsReducer,
  show: showReducer,
  currentUser: currentUserReducer,
  sessions: sessionsReducer,
  pastShows: allShowsPastReducer,
  pageNotFound: pageNotFoundReducer,
  internalServerError: internalServerErrorReducer,
  hashtags: hashtagsReducer,
  exhibitorSearch: exhibitorSearchReducer,
  exhibitors: exhibitorsReducer,
  selectedTheme: themeSwitchReducer,
  contacts: contactsReducer,
  products: productsReducer,
  productsOfAccount : productsOfAccountReducer,
  chat: chatReducer,
  attendeeQuestions: attendeeQuestionsReducer,
  sponsors: sponsorsReducer,
  attendees: attendeesReducer,
  products: productReducer,
  contact: contactReducer, // current logged in contact
  registration: registrationReducer,
  accountSearch: accountSearchSlice,
  excursion: excursionReducer
})