import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import getAxios from 'common/axios';
const axios = getAxios();

const REACT_APP_SERVER_BASE_URL = process.env.REACT_APP_SERVER_BASE_URL;

// fetch contact by name and email
export const fetchContactByNameAndEmail = createAsyncThunk(
  'registration/fetchContactByNameAndEmail',
  async ({email, accountId, showId}, {rejectWithValue}) => {
    try {
      const { data } = await axios.get(`${REACT_APP_SERVER_BASE_URL}/floorplan/contact/search`, {
        withCredentials: true,
        params: {
          email,
          accountId,
          showId
        }
      });
      return data.contact;
    } catch (err) {
      return rejectWithValue(err.response?.data || { message: 'An error occurred' });
    }
  }
);

const registrationSlice = createSlice({
  name: 'registration',
  initialState: {
    contact: {}, 
    status: "idle",
    error: null
  },
  reducers: {
    resetError: (state) => {
      state.error = null;
      state.status = 'idle';
    },
    updateRegistrationContact: (state, action) => {
      if (action.payload && Object.keys(action.payload)?.length === 0) {
        state.contact = {};
      } else if (action?.payload) {
        state.contact = {
          ...state.contact,
          ...action.payload,
        };
      }
    },
    setContactDetails: (state, action) => {
      // Directly updates the contact details in the store
      state.contact = {
        ...state.contact,
        ...action.payload
      };
    // state.status = "idle"; // Reset status if needed
    // state.error = null; // Clear any previous errors
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchContactByNameAndEmail.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchContactByNameAndEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.contact = action.payload;
      })
      .addCase(fetchContactByNameAndEmail.rejected, (state, action) => {
        state.status = "failed";
        state.contact = {};
        state.error = action.payload;
      });
  }
});

export const {resetError, updateRegistrationContact, setContactDetails} = registrationSlice.actions;
export default registrationSlice.reducer;
